
.check-board { 
  background-color: grey;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 75%, rgba(0, 0, 0, 0.2)), linear-gradient(45deg, rgba(0, 0, 0, 0.2) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.2) 75%, rgba(0, 0, 0, 0.2));
  background-size: 50px 50px;
  background-position: 0 0, 25px 25px;
}

@media (prefers-reduced-motion: no-preference) {
  
  
}